

.animated-text {
  position: relative;
  opacity: 0;
  transform: translateZ(0);

  @for $i from 1 to 100 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.05s;
    }
  }
}

.app:not(.loading) .animated-text.inView {
  animation: animate-up 0.7s forwards;
}


@keyframes animate-up {
  0% {
    opacity: 0;
    top: 100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
