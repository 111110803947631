@import "../mixins";

.findUs {
  display: flex;

  @include border;

  .sphere {
    width: 33%;
    padding: 46px 77px 24px 88px;
  }

  .contacts {
    width: 100%;
    padding: 0 24px;

    .contacts-item {
      padding: 24px 0;
      display: flex;
      align-items: center;
      font-size: 20px;

      a {
        color: $textMain;
        text-decoration: none;
      }

      svg {
        margin-right: 24px;
      }

      &.socials {
        display: flex;
        gap: 32px;

        .socials-item {
          text-decoration: underline;
          transition: opacity .2s;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px dashed #797979;
      }
    }
  }

  @include mobile {
    flex-direction: column-reverse;

    .sphere {
      width: 100%;
      border: none;
    }

    .contacts {
      padding: 0 16px;

      .contacts-item {
        font-size: 16px;

        &.socials {
          row-gap: 4px;
          column-gap: 16px;
          justify-content: space-between;
          flex-wrap: wrap;
          border-bottom: 1px dashed #797979;
        }
      }
    }
  }
}
