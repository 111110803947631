

.dashed-divider {
  width: 100%;
  height: 1px;

  svg {
    transform: translateY(-12px);
  }
}
