@import "../mixins";

.reviews {
  height: 392px;
  display: flex;
  @include border;

  &-item {
    width: 33%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .rate {
      display: flex;
      flex-direction: column;

      span {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }

    .comment {
      font-size: 18px;
      line-height: 140%;
      margin-top: 32px;
    }

    span {
      line-height: 140%;
    }
  }

  @include mobile {
    height: auto;
    flex-direction: column;

    &-item {
      width: 100%;
      padding: 24px 16px;
      border-left: none !important;

      &:not(:first-child) {
        //border-top: 1px solid $gray;
      }
    }
  }
}
