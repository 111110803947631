@import "topContainer";
@import "accordionContainer";
@import "selectedWorks";
@import "medium";
@import "about";
@import "reviews";
@import "findUs";
@import "forms";
@import "input";
@import "pagination";
@import "detailContent";
@import "dashedDivider";
@import "expandableTable";
@import "bline";
@import "animatedText";


.tags {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;

  span {
    font-size: 18px;
    padding: 4px 8px;
    border-radius: 4px;
    background: $gray;
    height: fit-content;
    line-height: 130%;
  }

  &.bordered {
    gap: 10px;
    span {
      border: 1px solid #fff;
      border-radius: 0;
      background: transparent;
    }
  }

  @include mobile {
    gap: 8px;

    span {
      font-size: 16px;
    }

    &.bordered {
      span {
        font-size: 18px;
      }
    }
  }
}
