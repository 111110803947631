@import "../mixins";

.contacts {

  &-content {
    display: flex;
    @include border;
  }

  &-links {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    margin: 52px 0;

    &-item {
      display: flex;
      align-items: center;
      padding: 24px 0;
      font-size: 20px;
      color: #fff;
      text-decoration: none;

      .underline {
        text-decoration: underline;
      }

      svg {
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 24px;
      }
    }
  }

  &-map {
    flex: 1 0 50%;

    & > .leaflet-container {
      width: 100%;
      height: 100%;
      background: $bg;

      .leaflet-control-container {
        display: none;
      }
    }
  }

  &-socials {
    display: flex;
    @include border;

    a {
      color: #fff;
      flex: 1 0 25%;
      text-align: center;
      padding: 47px 0;
      font-size: 20px;
      transition: background-color .2s ease;

      &:hover {
        background: var(--bg);
      }

      &:not(:first-child) {
        border-left: 1px solid $gray;
      }
    }
  }

  @include mobile {
    &-content {
      flex-direction: column-reverse;
    }

    &-links {
      margin: 0;
      padding: 0 16px;
    }

    &-map {
      flex: 1 0 100%;
      padding: 16px;
      min-height: 40vh;
      position: relative;
      margin-bottom: 24px;

      .leaflet-container {
        position: absolute;
        width: calc(100% - 32px);
      }
    }

    &-socials {
      flex-direction: column;
      border-bottom: none;

      a:not(:first-child) {
        border-top: 1px solid $gray;
        border-left: none;
      }
    }
  }
}
