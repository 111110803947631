@import "../mixins";

.bline {
  background-color: $gray;
  transition: width 1s ease, height 1s ease;
  transform: translateZ(0);

  &:not(.vertical) {
    width: 0;
    height: 1px;
  }

  &.vertical {
    height: 0;
    width: 1px;
  }
}

.app:not(.loading) .bline {
  &:not(.vertical).inView {
    width: 100%;
  }

  &.vertical.inView {
    height: 100%;
  }
}
