@import "../mixins";


.search-input {
  position: relative;

  svg {
    position: absolute;
    left: 24px;
    top: 22px;
    width: 24px;
    height: 24px;
    z-index: 1;
  }

  input {
    padding: 24px 24px 24px 57px;
    font-size: 18px;
    background: $bg;
    border: 1px solid $gray;
    border-radius: 4px;
    outline: none;
    position: relative;
    color: #fff;
    box-sizing: border-box;

    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath d='M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z' stroke='%238C8C8C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.9984 21.4984L16.6484 17.1484' stroke='%238C8C8C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      position: absolute;
      left: 24px;
      top: 24px;
      width: 24px;
      height: 24px;
    }

    &::placeholder {
      color: #616161;
    }
  }

  @include mobile {
    width: 100%;

    input {
      padding: 8px 8px 8px 56px;
    }

    svg {
      top: 6px;
      left: 24px;
    }
  }
}
