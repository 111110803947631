
@import "../mixins";

$transitionTime: 250ms;

.pagination {
  display: flex;
  justify-content: center;
  line-height: 140%;

  &-content {
    display: flex;
    gap: 16px;
  }

  &-button {
    padding: 4px 8px;
    background: $primary;
    border-radius: 8px;
    color: #000;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: all $transitionTime ease;

    svg {
      transition: all $transitionTime ease;
    }

    &:hover {
      opacity: 0.8;
    }

    &.back:hover svg {
      transform: translateX(-4px);
    }
    &.next:hover svg {
      transform: translateX(4px);
    }

    @include mobile {
      height: 32px;

      span {
        display: none;
      }
    }
  }

  &-main {
    display: flex;
    border-radius: 8px;
    border: 1px solid #616161;
    overflow: hidden;

    &-item {
      padding: 8px 16px;
      transition-property: color, background-color, opacity;
      transition-duration: $transitionTime;

      &.active {
        color: #000;
        background-color: $primary;
      }

      &:not(:first-child) {
        border-left: 1px solid #616161;
      }

      &:not(.active) {
        cursor: pointer;

        &:hover {
          background-color: #616161;
        }
      }
    }

    @include mobile {
      height: 32px;

      &-item {
        font-size: 14px;
        padding: 4px 16px;
      }
    }
  }

  @include mobile {
    padding: 0 16px;
    justify-content: space-between;

    &-content {
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

}
