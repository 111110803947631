@import "../mixins";

.expandable-table-container {
  @include border;

  &:hover {
    .expandable-table-item {
      opacity: 0.5;
    }
  }

  .expandable-table {
    display: flex;
    flex-wrap: wrap;
    height: 610px;
    overflow: hidden;

    @include mobile {
      height: fit-content;
    }

    &-item {
      $activeItemWidth: 469px;
      $transition: all 400ms;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 25%;
      height: 50%;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      color: $textMain;
      text-decoration: none;

      margin-right: -1px;
      padding: 18px;
      transition: $transition;
      border: 1px solid transparent;

      &:not(.firstItemInRow) {
        border-left-color: $gray;
      }
      &:not(.lastRowItem) {
        border-bottom-color: $gray;
      }
      &.firstItemInRow {
        flex-grow: 1;
      }

      &:hover {
        opacity: 1 !important;
        border-color: gray;
      }

      @include mobile {
        width: 100%;
        height: 306px;
        opacity: 1 !important;

        border: 0;
        &:not(:first-child) {
          border-top: 1px solid $gray;
        }
      }

      .tags {
        margin-bottom: 16px;
        padding-right: 20px;
        transition: $transition;

        span {
          border-radius: 0;
          font-size: 16px;
        }
      }

      &-close {
        position: absolute;
        right: 18px;
        top: 18px;
        z-index: 1;
        transition: $transition;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }

      &-img {
        flex-grow: 1;
        transition: $transition;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 120%;
        text-transform: capitalize;
        width: fit-content;
        max-width: 100%;
        margin-top: 16px;
        white-space: nowrap;
        transition: $transition;
        margin-bottom: 1px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 0.5px;
          width: 100%;
          background: white;
        }
      }

      &-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        margin-top: 8px;
        transition: $transition;
        max-height: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0;
        margin-top: 20px;
        background: $primary;
        border-radius: 4px;
        transition: $transition;

        color: #000;
        font-size: 18px;
        line-height: 120%;
        text-transform: capitalize;
        text-decoration: none;

        &:hover {
          opacity: 0.7;
        }
      }

      &-content {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
        justify-content: flex-end;
        flex-direction: column;
        position: relative;

        & > .expandable-table-item-title {
          position: absolute;
        }

        &-exp {
          opacity: 0;
          width: 100%;
          transition: $transition;
          min-width: $activeItemWidth;

          display: flex;
          flex-direction: column;
          flex-grow: 1;

          @include mobile {
            display: none;
          }
        }
      }

      &:not(.active) {
        .expandable-table-item-content-exp {
          pointer-events: none;

          & > .expandable-table-item-title {
            opacity: 0;
          }
        }

        .expandable-table-item-close {
          opacity: 0;
        }
      }

      &.active {
        overflow: hidden;
        cursor: default;

        .tags {
          min-width: $activeItemWidth;
        }

        .expandable-table-item-content {
          .expandable-table-item-content-exp {
            opacity: 1;
          }

          & > .expandable-table-item-title {
            opacity: 0;
            transform: translateY(-50%);
          }
        }

        .expandable-table-item-link {
          max-height: 40px;
        }
      }
    }

    @include fromMobile {
      &.hasActive {
        .expandable-table-item {
          opacity: 1;
        }

        .expandable-table-item:not(.activeCol) {
          height: 60px;
          width: 19%;
        }

        .expandable-table-item.activeCol {
          width: 43%;
        }

        .expandable-table-item.activeRow {
          height: calc(100% - 60px);
        }

        .expandable-table-item:not(.activeRow) {
          height: 60px;

          .tags {
            max-height: 0;
            margin: 0;
            overflow: hidden;
          }

          .expandable-table-item-title {
            margin-top: 0;
          }
        }
      }
    }
  }
}
