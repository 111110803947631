@import "../mixins";

.detailWork-page {

  .detail-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;
    @include border;

    .title {
      width: 100%;
      border: none;
    }

    .links {
      display: flex;
      gap: 8px;
    }

    @include mobile {
      padding-bottom: 24px;

      .title {
        text-align: center;
        padding: 16px;
      }
    }
  }
}
