@import "mixins";


html, body {
  background: $bg;
  color: $textMain;
  height: 100%;
}

#root {
  height: 100%;
}

.container {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  & header {
    height: 56px;
    position: sticky;
    top: 0;
    background: $bg;
    z-index: 1;
    @include border($gray, true);
    display: flex;
    flex-direction: column;

    .header-main {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .header-logo {
      padding: 8px 24px;
    }

    .mobileMenu_btn {
      display: none;
    }

    @include mobile {
      .header-logo {
        padding: 8px 16px;
      }

      nav {
        display: none;
      }

      .mobileMenu_btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        height: 100%;
        border-left: 1px solid $gray;
        padding-right: 16px;
        cursor: pointer;

        button {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          background: $primary;
          border: none;
          position: relative;
          transition: background-color .2s ease;

          &:hover,
          &:active {
            background: $primaryDark;
          }

          &:after {
            content: url("../../assets/icons/menu.svg");
            width: 24px;
            height: 24px;
            @include absoluteCenter;
          }

          &.active:after {
            content: url("../../assets/icons/arrowLeft.svg");
          }
        }
      }
    }
  }

  & main {
    flex-grow: 1;
    overflow: hidden;
  }

  & footer {
    padding: 8px 24px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    @include border;
    border-bottom: none;

    .designed {
      color: #fff;

      img {
        margin-left: 8px;
      }
    }

    a {
      color: $primary;
      text-decoration: underline;
    }

    .codeFactory {
      color: $primary;
    }

    @include mobile {
      padding: 8px 12px;
      align-content: center;
      justify-content: space-between;
      flex-wrap: wrap;
      text-align: center;
      color: #1C1C1C;

      .designed {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }
}

.navigation {
  &, ul, li, a {
    height: 100%;
    flex-grow: 1;
  }

  & ul {
    display: flex;

    li {
      border-left: 1px solid $gray;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $textMain;
        text-decoration: none;
        transition: background-color .2s ease;

        &:hover,
        &.active {
          background: #494949;
        }
      }
    }

    @include mobile {
      flex-direction: column;
      padding: 64px 0 176px;
      height: fit-content;

      li {
        border-left: none;
        border-top: 1px solid $gray;
        height: fit-content;

        &:last-child {
          border-bottom: 1px solid $gray;
        }

        a {
          font-size: 40px;
          line-height: 140%;
          font-weight: 700;
          padding: 8px 16px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.mobileMenu {
  position: fixed;
  top: 56px;
  left: -1px;
  width: calc(100% + 2px);
  max-height: 0;
  overflow: hidden;
  background: $bg;
  z-index: 1;
  transition: max-height .2s ease;

  .navigation {
    display: block;
  }

  &.open {
    height: calc(100% - 56px);
    max-height: calc(100% - 56px);
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $bg;
  border-bottom: 1px solid $gray;
  animation: preloader 1s linear;
  z-index: 5;
}

@keyframes preloader {
  0% {
    height: 1500px;
  }
  100% {
    height: 0;
  }
}
