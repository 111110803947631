@import "../mixins";

.works {

  &-searchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 77px;
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;

    .title {
      border: none;
    }

    @include mobile {
      border: none;
      padding-bottom: 32px;

      .title {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $gray;
        margin-bottom: 16px;
      }
    }
  }

  &-search {
    display: flex;
    justify-content: center;

    input {
      width: 590px;
    }

    @include mobile {
      width: 100%;
      padding: 0 20px;

      input {
        width: 100%;
      }
    }
  }

  .selectedWorks {
    border-bottom: none;

    @include mobile {
      border-top: none;
    }
  }

  &-pagination {
    padding: 31px 0;

    @include fromMobile {
      border-left: 1px solid $gray;
      border-right: 1px solid $gray;
      padding: 52px 0 42px;
    }
  }
}
