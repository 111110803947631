@import "../mixins";

.detail-content {

  & .title {
    text-align: center;
  }

  &-block {

    &.image,
    &.gallery,
    &.description, {
      @include border;
      border-top: none;

      &:last-child {
        border-bottom: none;
      }
    }

    &.image {
      height: 50vh;
      padding: 24px;

      .image-container {
        border-radius: 8px;
        background: #D9D9D9;
        overflow: hidden;

        &, img {
          width: 100%;
          height: 100%;
        }
      }

      @include mobile {
        height: 200px;
        padding: 16px;
      }
    }

    &.description {
      .title {
        border: none;
      }

      .description-content {
        display: flex;

        @include mobile {
          flex-direction: column;
        }
      }

      .description-item {
        display: flex;
        align-items: center;
        padding: 16px 24px;

        &-icon {
          width: 48px;
          min-width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 38px;
          border: 1px solid $primary;
          background: rgba(218, 166, 255, 0.1);

          margin-right: 12px;
          box-sizing: border-box;
        }

        &.inList {
          .inList-title {
            font-size: 20px;
            font-weight: 400;
            line-height: 140%;
            margin-right: 16px;
            width: 136px;
          }
        }

        @include mobile {
          padding: 16px;

          &.inList {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .inList-title {
              font-size: 16px;
              margin-bottom: 8px;
            }
          }
        }
      }

      .description-stack,
      .description-text {
        flex: 1 0 50%;
      }

      .description-technologies {
        display: flex;
        flex-direction: column;

        .description-items {
          display: flex;
          flex-wrap: wrap;
        }

        .description-item {
          flex: 1 0 50%;

          &:nth-child(2n) {
            border-left: 1px solid $gray;
          }
        }
      }

      .description-text {
        padding: 32px;
        border-left: 1px solid $gray;

        & p {
          font-size: 18px;
          font-weight: 400;
          line-height: normal;
        }

        @include mobile {
          border-left: none;
          padding: 24px 16px;
          border-top: 1px solid $gray;
        }
      }
    }

    &.gallery {
      .title {
        border: none;
      }

      .gallery-content {
        display: flex;

        @include mobile {
          flex-direction: column;
        }
      }

      .gallery-list {
        flex: 1 0 70%;
        padding: 24px;
        overflow: hidden;
        display: flex;
        gap: 24px;
        height: 400px;

        &-item {
          border-radius: 8px;
          background: #D9D9D9;
          transition: all 400ms;
          transform: translateX(var(--translateX));

          &, img {
            width: 100%;
            min-width: 100%;
            height: 100%;
            min-height: 100%;
          }
        }

        @include mobile {
          gap: 16px;
          padding: 16px;

          img {
            min-height: 190px;
          }
        }
      }

      .gallery-controls {
        flex: 1 0 30%;
        border-left: 1px solid $gray;
        display: flex;
        flex-direction: column;
        line-height: normal;

        &-counter,
        &-label,
        &-buttons {
          padding: 24px;
        }

        &-counter {
          flex-grow: 1;
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 40px;
        }

        &-label {
          text-align: center;
          font-size: 18px;
          @include font;
        }

        &-buttons {
          display: flex;
          justify-content: space-between;

          &-item {
            width: 64px;
            height: 64px;
            position: relative;
            border-radius: 500px;
            border: 2px solid $gray;
            cursor: pointer;

            &:after {
              content: "";
              width: 16px;
              height: 16px;
              border-radius: 2px;
              border-left: 2px solid $gray;
              border-bottom: 2px solid $gray;
              transition: transform 250ms ease;

              @include absoluteCenter;
            }

            &.prev:after {
              transform: translate(calc(-50% + 2px), -50%) rotate(45deg);
            }

            &.next:after {
              transform: translate(calc(-50% - 2px), -50%) rotate(-135deg);
            }

            &:active {
              &.prev:after {
                transform: translate(-50%, -50%) rotate(45deg);
              }

              &.next:after {
                transform: translate(-50%, -50%) rotate(-135deg);
              }
            }
          }
        }

        @include mobile {
          border-left: none;
          border-top: 1px solid $gray;

          & > div {
            padding: 8px 24px;
          }

          &-counter {
            font-size: 24px;
          }

          &-label {
            font-size: 14px;
          }

          &-buttons {
            padding: 16px !important;

            &-item {
              width: 48px;
              height: 48px;

              &.prev:after {
                transform: translate(calc(-50% + 4px), -50%) rotate(45deg) !important;
              }

              &.next:after {
                transform: translate(calc(-50% - 4px), -50%) rotate(-135deg) !important;
              }

              &:active {
                &.prev:after {
                  transform: translate(calc(-50% + 2px), -50%) rotate(45deg) !important;
                }

                &.next:after {
                  transform: translate(calc(-50% - 2px), -50%) rotate(-135deg) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
