@import "../mixins";

.selectedWorks {
  @include border;

  .table {
    display: flex;
    flex-direction: column;

    &-head {
      display: flex;
      padding:  9px 0;
      align-items: center;
      border-bottom: 1px solid $gray;

      span {
        font-size: 20px;
        text-transform: capitalize;
        text-align: left;
        padding-left: 16px;
        flex: 1 0 calc(25% - 11px);
        display: flex;
        align-items: center;

        svg {
          cursor: pointer;
        }

        &:first-child {
          padding-left: 36px;
          flex: 1 0 calc(25% + 33px);
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;

      &-row {
        display: flex;
        height: 158px;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color .3s ease;
        color: #fff;
        text-decoration: none;

        &:hover {
          background: #353535;

          & + .divider {
            border-top-color: transparent;
          }
        }

        & > div {
          flex: 1 0 calc(25% - 11px);
        }

        .name {
          flex: 1 0 calc(25% + 33px);
          padding: 8px 36px;
          justify-content: center;
          font-size: 24px;
          text-decoration: underline;
        }

        .tags {
          padding: 16px;
        }
      }

      .divider {
        margin: 0 36px;
        border-top: 1px dashed $gray;
        transition: all .3s ease;
      }
    }

    @include mobile {
      margin-top: 16px;

      &-head {
        display: none;
      }

      &-body {
        &-row {
          flex-direction: column;
          height: fit-content;
          align-items: flex-start;

          &:hover {
            background: transparent;

            & + .divider {
              border-top-color: $gray;
            }
          }

          .name {
            flex: unset;
            padding: 16px 0;
            text-align: center;
            width: 100%;
          }
        }

        .divider {
          margin: 16px;
        }
      }
    }
  }
}
