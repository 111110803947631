@import "../mixins";

.accordionContainer {
  display: flex;
  @include border;
  border-top: 0;
  border-bottom: 0;

  .sections {
    width: 33%;
    min-width: 33%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $gray;

    &-item {
      color: $textMain;
      padding: 50px 24px;
      text-align: center;
      font-size: 20px;
      cursor: pointer;
      border: 1px solid transparent;
      transition: color .2s ease, background-color .2s ease, border-radius .2s ease;
      display: flex;
      justify-content: space-between;

      &-icon {
        height: 40px;
        width: 40px;
        margin-right: 10px;
      }

      &-arrow {
        display: none;

        path {
          transition: all .2s ease;
        }
      }

      &:hover {
        background-color: #494949;
      }

      &.active {
        color: #141313;
        background-color: $primary;

        .section-item-arrow {
          transform: rotate(180deg);

          path {
            stroke: #1C1C1C;
          }
        }
      }

      &:hover, &.active {
        border-radius: 4px;
        border-color: transparent;
      }
    }

    & > div:not(:first-child) .sections-item {
      //border-top: 1px solid $gray;
    }

    .activeSection {
      display: none;
    }
  }

  .activeSection {
    padding: 48px;
    flex-grow: 1;
    overflow: scroll;
    line-height: 140%;
    max-height: 609px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;

      @include mobile {
        display: none;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: white;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      width: 4px;
      border-left: 1px solid $gray;
    }

    h3 {
      text-transform: uppercase;
      margin-bottom: 4px;
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }

    &:not(:first-child) h3 {
      margin-top: 16px;
    }
  }

  @include mobile {
    border-top: 0;
    & > .sections {
      width: 100%;
      border-right: 0;
      //border-bottom: 1px solid $gray;

      .sections-item {
        padding: 24px 16px;
        text-align: left;
        //border-top: 1px solid $gray;

        &-icon {
          width: 24px;
          height: 24px;
        }

        &-arrow {
          display: block;
        }
      }

      .activeSection {
        display: block;
        max-height: 0;
        padding: 0 16px;
        transition: all .2s ease;

        h3 {
          margin-bottom: 8px;
          font-size: 16px;
        }

        &:not(:first-child) h3 {
          margin-top: 24px;
        }
      }

      .sections-item.active + .activeSection {
        max-height: 1600px;
        padding: 24px 16px;
      }
    }

    & > .activeSection {
      display: none;
    }
  }
}
