@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "mixins";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  @include font;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;

  @include mobile {
    font-size: 32px;
  }
}

h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
  position: relative;

  &.title {
    --button-offset: 24px;

    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    padding: 32px 24px;
    text-align: center;
    overflow: hidden;

    .roundedButton {
      position: absolute;
      right: var(--button-offset);
      top: 50%;
      transform: translateY(-50%);
      border: none;
      outline: none;
      cursor: pointer;
    }

    .reverse {
      left: var(--button-offset);
    }
  }

  @include mobile {
    font-size: 24px;

    &.title {
      --button-offset: 16px;

      text-align: left;
      padding: 24px 16px;
      border-left: none;
      border-right: none;
    }
  }
}

.roundedButton {
  width: 64px;
  height: 64px;
  background: $primary;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  transition: background-color .2s ease;

  &:after {
    content: "";
    top: 8px;
    left: 8px;
    width: 48px;
    height: 48px;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.33594 14.668L5.33594 17.3346L21.3359 17.3346L21.3359 20.0013L24.0026 20.0013L24.0026 17.3346L26.6693 17.3346L26.6693 14.668L24.0026 14.668L24.0026 12.0013L21.3359 12.0013L21.3359 14.668L5.33594 14.668ZM18.6693 9.33463L21.3359 9.33463L21.3359 12.0013L18.6693 12.0013L18.6693 9.33463ZM18.6693 9.33463L16.0026 9.33464L16.0026 6.66797L18.6693 6.66797L18.6693 9.33463ZM18.6693 22.668L21.3359 22.668L21.3359 20.0013L18.6693 20.0013L18.6693 22.668ZM18.6693 22.668L16.0026 22.668L16.0026 25.3346L18.6693 25.3346L18.6693 22.668Z' fill='black'/%3E%3C/svg%3E");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    transition: left .2s ease;

    @include absoluteCenter;
  }

  &:hover {
    background: $primaryDark;

    &:after {
      left: calc(50% + 4px);
    }
  }

  &.close,
  &.check {
    &:hover:after {
      left: 8px;
    }
  }

  &.close {
    background: #fff;

    &:after {
      background-image: url("../../assets/icons/xIcon.svg");
    }
  }

  &.check {
    background: $success;

    &:after {
      background-image: url("../../assets/icons/check.svg");
    }
  }

  &.transparent {
    background: transparent;
    border: 2px solid $primary !important;

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'%3E%3Cpath d='M8.3444 22.9393C7.75861 23.5251 7.75861 24.4749 8.3444 25.0607L17.8903 34.6066C18.4761 35.1924 19.4259 35.1924 20.0117 34.6066C20.5975 34.0208 20.5975 33.0711 20.0117 32.4853L11.5264 24L20.0117 15.5147C20.5975 14.9289 20.5975 13.9792 20.0117 13.3934C19.4259 12.8076 18.4761 12.8076 17.8903 13.3934L8.3444 22.9393ZM41 22.5L9.40506 22.5L9.40506 25.5L41 25.5L41 22.5Z' fill='%23DAA6FF'/%3E%3C/svg%3E");
    }

    &:hover:after {
      left: calc(50% - 4px);
    }
  }

  @include mobile {
    width: 32px;
    height: 32px;

    &:after {
      width: 24px;
      height: 24px;
      background-size: 24px;

      &:hover:after {
        left: calc(50% - 2px);
      }
    }
  }
}

@import "layout";
@import "pages";
@import "components";
