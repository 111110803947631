@import "../mixins";

.about {
  display: flex;
  padding: 24px 48px;
  gap: 41px;

  @include border;

  .col {
    flex: 1 0 20%;

    &-head {
      .stroke {
        max-width: 100px;
        font-size: 64px;
        line-height: 120%;

        svg {
          overflow: visible;
        }

        text {
          fill: transparent;
          stroke: #FFFFFF;
          stroke-width: 2;
        }
      }

      span {
        font-size: 24px;
        font-weight: 700;
        line-height: 140%;
        text-transform: lowercase;
      }
    }

    &-content {
      font-size: 14px;
      line-height: 140%;
      margin-top: 24px;

      ul {
        padding-left: 6px;

        li {
          list-style: disc inside;
        }
      }
    }
  }

  @include mobile {
    flex-direction: column;
    overflow: hidden;
    padding: 24px 16px;
    gap: 24px;

    .col {

      &-head {
        .stroke {
          font-style: italic;
          font-size: 109px;
        }

        span {
          text-transform: capitalize;
        }
      }

      &-content {
        margin-top: 16px;
      }
    }
  }
}
