@import "../mixins";

.medium {
  display: flex;
  line-height: 140%;

  @include border;

  & > div {
    width: 50%;
    height: 454px;
    position: relative;

    &:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 38px 24px 24px;

      img {
        width: 213px;
        align-self: center;
      }

      h3 {
        font-size: 32px;
        font-weight: 400;
        margin: 24px 0;
      }
    }

    &:nth-child(2) {
      background: $primary;
    }
  }

  .readMore {
    color: #000;
    font-size: 24px;
    position: absolute;
    bottom: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      border-radius: 50%;
      box-sizing: unset;
      transition: transform 250ms;
    }

    &:hover .icon {
      transform: translate(4px, -4px);
    }
  }

  @include mobile {
    flex-direction: column;

    & > div {
      width: 100%;

      &:nth-child(2) {
        border-radius: 8px;
      }
    }
  }
}
