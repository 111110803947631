@import "../mixins";

.forms {

  &-section {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 16px 110px 16px 24px;
    height: 128px;
    @include border;

    .roundedButton {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      outline: none;
      cursor: pointer;
    }

    &--content {
      flex-grow: 1;
      max-width: 50%;
    }

    &.error {
      input {
        color: #FF4141 !important;
      }
    }

    &.success {
      input {
        color: #71FF5A !important;
      }
    }
  }

  .feedback .forms-section--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .input {
      width: 100%;
    }
  }

  &-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
  }

  &-subTitle {
    font-size: 40px;
    line-height: 140%;
    text-transform: uppercase;
  }

  .input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    input {
      width: 100%;
      font-size: 40px;
      color: #FFF;
      background: none;
      border: none;
      outline: none;
      @include font;

      &::placeholder {
        color: #8C8C8C;
        text-transform: uppercase;
        font-weight: 400;
      }
    }

    .error-message {
      color: #FF4141;
      margin-top: 4px;
    }
  }

  @include mobile {
    &-section {
      padding: 16px;
      height: auto;
      text-align: left;
      align-items: flex-start;

      &--content {
        max-width: 100%;
      }

      .roundedButton {
        right: 16px;
      }
    }

    &-title, &-subTitle {
      font-size: 32px;
    }

    .call {
      .forms-title {
        display: none;
      }

      .forms-subTitle {
        font-weight: 700;
      }

      .roundedButton {
        top: 0;
        transform: translateY(calc(50% + 10px));
      }
    }

    .feedback {
      text-align: center;
      flex-direction: column;

      .roundedButton {
        top: 100%;
        transform: translateY(calc(-150% - 4px));
      }
    }

    .input {
      display: flex;

      input {
        font-size: 24px;
        padding: 0;
        margin-top: 24px;
      }

      .error-message {
        color: #FF4141;
        margin-top: 4px;
        font-size: 14px;
      }
    }
  }

}
