@import "../mixins";


.canvasWrapper {
  background-color: transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

  .tags {
    &.bordered {
      span {
        background: $bg !important; // sorry
      }
    }
  }

.topContainer {
  z-index: 0;
  height: 728px;
  background: transparent;
  position: relative;
  @include border();
  border-top: none;
  border-bottom: none;

  display: flex;
  flex-direction: column;

  &-main {
    flex-grow: 1;
  }

  &-content {
    display: flex;

    &-space {
      flex: 1 0 258px;
      background-color: $bg;
      display: flex;
      justify-content: flex-end;
    }

    &-title {
      flex-grow: 1;
      padding: 42px 0 32px 38px;

      & h1 {
        font-size: 90px;
        font-weight: 800;
        line-height: 90%;
        text-transform: uppercase;
        margin-bottom: 30px;
      }
    }
  }

  &-divider {
    background: $bg;
    height: 20px;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
  }

  @include mobile {
    height: 600px;
    background: transparent;

    &-content {
      height: 100%;

      &-space {
        display: none;
      }
      &-title {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding: 54px 16px 24px;

        & h1 {
          font-size: 32px;
          font-weight: 700;
        }
      }
    }

    &-divider {
      display: none;
    }

  }
}

.app:not(.loading) .topContainer-divider {
  @include sizeTo100;
}
